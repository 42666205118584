import { IsString, IsUrl, MinLength } from "class-validator";
import ValidatorJS from "validator";

import { AutoValidated } from "@/autovalidate";

const baseUrlValidation: ValidatorJS.IsURLOptions = {
  protocols: [ "http", "https" ],
  require_tld: false,
  require_protocol: true,
  require_host: true,
  require_valid_protocol: true,
  disallow_auth: true,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  allow_fragments: false,
  allow_query_components: false,
};

// TODO If you've updated this, remember to also update the build inputs in project.json
/** Env config that can be exposed to the client side.
 * Anything in here is also accessible serverside.
 * Note that only env variables starting with NEXT_PUBLIC can be
 * read client-side */
class PublicConfiguration extends AutoValidated {

  // Note: This is public, because the API key needs to be used client-side
  // in real-time preview/edit mode (and conditional tree-shaking is not
  // supported by webpack). This is a read-only API key anyways.
  @IsString()
  public readonly STORYBLOK_API_TOKEN: string = process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN!;

  @IsString()
  public readonly STORYBLOK_ENV: string = process.env.NEXT_PUBLIC_STORYBLOK_ENV ?? "published";

  @IsString()
  public NEXT_PUBLIC_COOKIE_DOMAIN: string = process.env.NEXT_PUBLIC_COOKIE_DOMAIN!;

  @IsString()
  public NEXT_PUBLIC_WEBSITE_URL: string = process.env.NEXT_PUBLIC_WEBSITE_URL!;

  @IsString()
  public NEXT_PUBLIC_FORM_ACTIONS_URL: string = process.env.NEXT_PUBLIC_FORM_ACTIONS_URL!;

  @IsString()
  public NEXT_PUBLIC_FORMS_STORAGE_URL: string = process.env.NEXT_PUBLIC_FORMS_STORAGE_URL!;

  @IsString()
  public NEXT_PUBLIC_STRIPE_PUBLIC_KEY: string = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!;

  @IsString()
  public NEXT_PUBLIC_FORMS_STRUCTURE_URL: string = process.env.NEXT_PUBLIC_FORMS_STRUCTURE_URL!;

  @IsUrl(baseUrlValidation)
  public AUTH_API_BASE_URL: string = process.env.NEXT_PUBLIC_AUTH_API_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public BLOG_API_BASE_URL: string = process.env.NEXT_PUBLIC_BLOG_API_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_OLD_API_BASE_URL: string = process.env.NEXT_PUBLIC_OLD_API_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_CAPI_BASE_URL: string = process.env.NEXT_PUBLIC_CAPI_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_ORDERS_BASE_URL: string = process.env.NEXT_PUBLIC_ORDERS_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_BREEDERS_API_BASE_URL: string = process.env.NEXT_PUBLIC_BREEDERS_API_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_STATISTICS_URL: string = process.env.NEXT_PUBLIC_STATISTICS_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_CHECKOUT_URL: string = process.env.NEXT_PUBLIC_CHECKOUT_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_FUNNEL_URL: string = process.env.NEXT_PUBLIC_FUNNEL_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_DISCOUNT_API_BASE_URL: string = process.env.NEXT_PUBLIC_DISCOUNT_API_BASE_URL!;

  @IsUrl(baseUrlValidation)
  public NEXT_PUBLIC_SURVEY_ANSWERS_BASE_URL: string = process.env.NEXT_PUBLIC_SURVEY_ANSWERS_BASE_URL!;

  @IsString()
  @MinLength(300)
  public readonly JWT_PUBLIC_KEY: string = process.env.NEXT_PUBLIC_JWT_PUBLIC_KEY!;

  @IsString()
  public SECURITY_API_BASE_URL: string = process.env.NEXT_PUBLIC_SECURITY_API_BASE_URL!;

  @IsString()
  public readonly ANIKIN_ENVIRONMENT: string = process.env.NEXT_PUBLIC_ANIKIN_ENVIRONMENT || "dev";

  @IsString()
  public DATADOG_RUM_CLIENT_TOKEN: string = process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || "pub8bd162f452d9c03c08fc168224086268";

  @IsString()
  public DATADOG_RUM_APP_ID: string = process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID || "a71eb84b-ffe2-4761-aa66-abfe4b2ca0bf";

}

const PublicConfig = PublicConfiguration.create();

export {
  PublicConfig,
};
