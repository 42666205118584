/** The names of the baggage that will be logged by the Meow logger
 * (Baggage is values stored in a trace context) */
export enum MeowTags {
  /** The user ID. Will be populated automatically from the ACCESS_TOKEN, if available. */
  SUB = "sub",
  /** The user email. Will be populated automatically from the ACCESS_TOKEN, if available. */
  EMAIL = "email",
  /** The datadog frontend session ID. Will be populated through the DD-Session header or _dd_s cookie if available. */
  DD_SESSION = "session_id",
  /** Automatically generated for each incoming request.
   * Useful if you want to follow the logs for a specific request. */
  DD_REQ_ID = "ddRequestId",
  /** The impersonated user ID. Will be populated automatically from the ACCESS_TOKEN, if available. */
  ISUB = "isub",
  ANON_USER_ID = "anonUserId",
  /** The legacy customer ID. Will be populated automatically from the ACCESS_TOKEN, if available.  */
  OLD = "oldSub",
  /** The legacy impersonated customer ID. Will be populated automatically from the ACCESS_TOKEN, if available.  */
  IOLD = "iOldSub",
  /** The ID of a customer in ReCharge. Name mirrors name used in kotlin-backend events */
  RECHARGE_CUSTOMER_ID = "rechargeCustomerId",
  /** Set this to a unique ID to help you tell the difference between logs of different parallel executions
   * (e.g. if you're doing Promise.all([x,y,z])
   * you can have x, y and z each start their own span with their own job ID).
   * You can see an example of how to generate and set job IDs below,
   * though any unique ID would do.
   * @example
   * import tracer from "dd-trace";
   *
   * //...
   *
   * private readonly startTime = Date.now();
   *
   * private iterCount = 0;
   *
   * //...
   *
   * const result = await tracer.trace(
   *   `doThingForX`,
   *   {
   *     service: "Some Service",
   *     resource: "Some endpoint",
   *     childOf: tracer.scope().active() ?? undefined,
   *   },
   *   async (span) => {
   *     const currIter = this.iterCount;
   *     this.iterCount += 1;
   *     if (span) {
   *       span.setBaggageItem(MeowTags.RECHARGE_CUSTOMER_ID, rechargeCustomerId.toString());
   *       span.setBaggageItem(MeowTags.DD_JOB_ID, `${this.startTime}_${currIter}`);
   *     }
   *     // Stuff...
   *     return resultOfExecutionOfSpan;
   *   },
   * );
   * */
  DD_JOB_ID = "ddJobId",
}
